import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { history } from "../history";
import { getEnv } from '../lib/function';
import Header from "./Header";
import Loader from './Loader';

const Redeem = ({ loginResult, user }) => {
    const [t] = useTranslation(['translation']);
    const [redeemCode, setRedeemCode] = useState('');
    const [error, setError] = useState('');
    const [isDone, setIsDone] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const code = searchParams.get('code');
        if(!user?._id){
            if (code) {
                localStorage.setItem('redeemCode', code);
            }
            history.push('/login');
        }else{
            if(code){
                setRedeemCode(code);
            }
        }
    }, [user?._id]);

    console.log(window.location.hostname);

    const register = async () => {
        setLoading(true);
        setError('')

        const res = await axios.post('https://api-manager-license.' + (getEnv() === "production" ? "rayteams.com" : "raydevelop.com") + '/annualpass/addlicense/' + redeemCode, {
            userinfo: {
                _id: user._id,
                groupid: user.groupId,
                countryCode: user.countryCode
            }
        });

        console.log(res.data.data);
        if(res.data.data.code !== "SUCCESS"){
            setError(res.data.data.code);
        }else{
            setIsDone(true);
        }
        setLoading(false);
    }

    const errorMessage = {
        "NOTFOUND": "코드 정보를 찾을 수 없습니다.",
        "USED": "이미 사용된 코드입니다.",
        "REGISTER_ERROR": "등록 중 문제가 발생 하였습니다."
    }

    return (
        // <div className="content-wrapper rt-site bg-gray">
        <>
            <Header />
            <section className="wrapper">
                <div className="container pt-14 pt-md-17 pb-10">
                    {loading && <div className="row">
                        <div className="col-lg-8 col-xl-7 col-xxl-6 mx-auto text-center pt-8">
                            <Loader isShow={true} />
                        </div>
                    </div>}
                    {!loading && <div className="row">
                        {!isDone && <div className="col-lg-8 col-xl-7 col-xxl-6 mx-auto text-center pt-8">
                            <h1 className="mb-3">{t('Redeem Code')}</h1>
                            <div className="mb-7 px-md-12 px-lg-5 px-xl-7">
                                <label className="lead">
                                    {t('제품 등록 후, 바로 사용 가능합니다.')}
                                </label>
                            </div>
                            {error && <div className="mb-7 px-md-12 px-lg-5 px-xl-7">
                                <label className="lead text-danger">{t(errorMessage[error])}</label>
                            </div>}
                            <div className="mb-7 px-md-12 px-lg-5 px-xl-7">
                                <input type="text" className="form-control text-center fs-24" placeholder={t('Redeem Code')} value={redeemCode} onChange={(e) => setRedeemCode(e.target.value)} />
                                <a className="btn btn-primary rounded-pill w-100 mt-1" disabled={!redeemCode} onClick={() => register()}>{t('제품 등록')}</a>
                            </div>
                            <div>
                                <label className="lead h5">
                                    {t('아래의 소프트웨어들이 활성화됩니다.')}
                                </label>
                            </div>
                            <div className="mb-1 px-md-12 px-lg-5 px-xl-7">
                                <label className="lead">{'RAYDENT Splint'}</label>  
                            </div>
                            <div className="mb-1 px-md-12 px-lg-5 px-xl-7">
                                <label className="lead">{'RAYDENT Designer'}</label>
                            </div>
                            <div className="mb-1 px-md-12 px-lg-5 px-xl-7">
                                <label className="lead">{'RAYDENT Tray'}</label>
                            </div>
                            <div className="mb-1 px-md-12 px-lg-5 px-xl-7">
                                <label className="lead">{'RAYFusion'}</label>
                            </div>
                            <div className="mb-1 px-md-12 px-lg-5 px-xl-7">
                                <label className="lead">{'Mockup Exporter'}</label>
                            </div>
                        </div>}
                        {isDone && <div className="col-lg-8 col-xl-7 col-xxl-6 mx-auto text-center pt-8">
                            <h1 className="mb-3">{t('제품 등록 완료')}</h1>
                            <div className="mb-7 px-md-12 px-lg-5 px-xl-7">
                                <label className="lead">{t('제품 등록이 완료되었습니다.')}</label>
                            </div>
                            <div className="mb-7 px-md-12 px-lg-5 px-xl-7 text-start mx-4">
                                <div>
                                    <label className="lead h5">
                                        {t('사용방법 안내')}
                                    </label>
                                </div>
                                <div className="mb-1 px-md-12 px-lg-5 px-xl-7">
                                    <label className="lead">{t('1. PC에 RAYTeams를 설치하고 로그인하세요.')}</label>  
                                </div>
                                <div className="mb-1 px-md-12 px-lg-5 px-xl-7">
                                    <label className="lead">{t('2. Apps에서 활성화된 소프트웨어를 선택하세요.')}</label>
                                </div>
                                <div className="mb-1 px-md-12 px-lg-5 px-xl-7">
                                    <label className="lead">{t('3. 설치 후 사용하세요.')}</label>
                                </div>
                            </div>
                            <div className="mb-7 px-md-12 px-lg-5 px-xl-7">
                                <a className="btn btn-primary rounded-pill mt-1" onClick={() => history.push('/')}>{t('홈으로')}</a>
                            </div>
                        </div>}
                    </div>}
                </div>
            </section>
        </>
        // </div>
    )
}

const mapState = (state) => {
    const user = state.AuthReducer.user;
    return { user };
}

const mapDispatch = dispatch => ({
});

export default connect(mapState, mapDispatch)(Redeem);