import _ from "lodash";
import { Buffer } from 'buffer';

export const EncryptData = async (originPayload) => {
    const inputText = JSON.stringify(originPayload);
    let texts = _.chunk(inputText, 150);
    texts = texts.map(x => x.join(""));
    const publicKey = await importPublicKey();
    const payloadData = [];
    for (const text of texts) {
        const encryptText = await encryptMessage(text, publicKey);
        payloadData.push(encryptText);
    }
    const payload = {
        data: payloadData,
    };
    return payload;
}

async function importPublicKey() {
    const publicKeyBase64 = "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA3PFSnnbICzJlkNhNyERAY4KQbdFZQj7mBJ5lfaRQWRiO1XFXDFt69E6f+GE7FMXKUy7VYbykopbNRjl+2pFKOjpW4CX9hHbPgNkH4HDq11Bk8suloWh7hxJMOjKZHXgPM6nypjARcbE5SNHgmChiAwS4Y+WofawRC4PH1lQw++JG1kuQ45sponY3tVhlS9Y6tiY7jXHORqDDFqwcEbn4Gb+CONNo5gt/tIX6M73wWOi8YB6byAxHt5405gg5mhq5r/SIjLTrNSIswShWuaWfW9jzgRHSX4N5WzZAh1MsqMFPjBY/AcjstFtbPuLUOv742oGjOoJ0ve3qm18MlTqI2QIDAQAB";
    const publicKeyBuffer = Uint8Array.from(Buffer.from(publicKeyBase64, "base64"));
    const publicKey = await window.crypto.subtle.importKey(
        "spki",
        publicKeyBuffer,
        {
            name: "RSA-OAEP",
            hash: "SHA-256"
        },
        true,
        ["encrypt"]
    );
    return publicKey;
}

async function encryptMessage(inputText, key) {
    let enc = new TextEncoder();
    let encoded = enc.encode(inputText);
    const cipherText = await window.crypto.subtle.encrypt(
        {
            name: "RSA-OAEP"
        },
        key,
        encoded
    );
    let buffer = new Uint8Array(cipherText);
    let hex = Buffer.from(buffer).toString('hex');
    return hex;
}






