import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { history } from "../history";
import { getEnv } from '../lib/function';
import Header from "./Header";
import Loader from './Loader';
import { saveAs } from 'file-saver';
import { useRef } from 'react';

const ReluRenewal = ({ loginResult, user }) => {
    const [t] = useTranslation(['translation']);
    const [error, setError] = useState('');
    const [isDone, setIsDone] = useState(false);
    const [loading, setLoading] = useState(false);
    const [licenseFile, setLicenseFile] = useState(null);
    const [createLicense, setCreateLicense] = useState(false);
    const fileInputRef = useRef(null);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const code = searchParams.get('code');
        
    }, [user?._id]);

    console.log(window.location.hostname);

    useEffect(() => {
        if(licenseFile){
            console.log("licenseFile===================", licenseFile);
            if (licenseFile.offline_request.split(":").length >= 2 && licenseFile.offline_request.split(":")[0] === "NRT") {
                setCreateLicense(true);
            }
        }
    }, [licenseFile]);

    const validResponseString = async (inputFile) => {
        try {
            const _text = await inputFile.text();
            const _array = _text.split(":");
            const _relulicenseid = _array.shift();
            const _offlineActivationCode = _array.join(":");

            return {relu_id: _relulicenseid, offline_request: _offlineActivationCode}
        } catch (error) {
            console.log("ERROR[validResponseString]", error?.response?.data || error?.toString());
            return false;
        }
    }

    const handlerOnFileChange = async (files) => {
        if (files.length === 0) {
            setLicenseFile(null);
            return;
        }
        const _file = files[0];
        if (_file.size > 1024 * 1024 * 1) {
            setLicenseFile(null);
            return;
        }
        const isValid = await validResponseString(_file);
        if (!isValid) {
            fileInputRef.current.value = null;
            setLicenseFile(null);
            return;
        }

        setLicenseFile({
            relu_id: isValid.relu_id,
            offline_request: isValid.offline_request
        });
    }

    const generateOfflineActivationCode = async () => {
        setLoading(true);
        setError('')
        console.log("createLicense ========================", createLicense);
        const res = await axios.post('https://api-manager-license.' + (getEnv() === "production" ? "rayteams.com" : "raydevelop.com") + '/commonRelu', {
            type: createLicense ? "createLicense" : "offlineActivationWeb",
            relu_id: licenseFile.relu_id,
            offline_request: licenseFile.offline_request
        });

        console.log("generateOfflineActivationCode ========================", res.data.data);
        if(isNaN(res.data.data?.expired)){
            console.log("1");
            setError(res.data.data.code);
        }else{
            if(res.data.data?.type === "ERROR"){
                console.log("2");
                setError(res.data.data.code);
            }else{
                try{  
                    console.log("3");
                    const blob = new Blob([JSON.stringify(res.data.data)], { type: "text/plain;charset=utf-8" });
                    const fileName = createLicense ? "license_key.txt" : "offline_activation_code.txt";
                    console.log("4");
                    saveAs(blob, fileName);
                    console.log("5");
                    setIsDone(true);
                }catch(error){
                    console.log("ERROR[generateOfflineActivationCode]", error?.response?.data || error?.toString());
                    setError("SAVE_ERROR");
                }
            }
            setIsDone(true);
        }
        setLicenseFile(null);
        setLoading(false);
    }

    const errorMessage = {
        "ALREADYRENEWAL": "이미 갱신 완료 한 Code 입니다.",
        "NOTFOUND": "코드 정보를 찾을 수 없습니다.",
        "USED": "이미 사용된 코드입니다.",
        "REGISTER_ERROR": "등록 중 문제가 발생 하였습니다.",
        "SAVE_ERROR": "파일 저장 중 문제가 발생 하였습니다."
    }

    return (
        // <div className="content-wrapper rt-site bg-gray">
        <>
            <Header />
            <section className="wrapper">
                <div className="container pt-14 pt-md-17 pb-10">
                    {loading && <div className="row">
                        <div className="col-lg-8 col-xl-7 col-xxl-6 mx-auto text-center pt-8">
                            <Loader isShow={true} />
                        </div>
                    </div>}
                    {!loading && <div className="row">
                        {!isDone && <div className="col-lg-8 col-xl-7 col-xxl-6 mx-auto text-center pt-8">
                            <h1 className="mb-3">{ createLicense ? t('Generate Key File') : t('Generate Offline Activation Code')}</h1>
                            <div className="mb-7 px-md-12 px-lg-5 px-xl-7">
                                <label className="lead">
                                    { createLicense ? t('키 파일 다운로드') : t('오프라인 활성화 코드 생성')}
                                </label>
                            </div>
                            {error && <div className="mb-7 px-md-12 px-lg-5 px-xl-7">
                                <label className="lead text-danger">{t(errorMessage[error])}</label>
                            </div>}
                            <div className="mb-7 px-md-12 px-lg-5 px-xl-7">
                                <input type="file" className="form-control text-center fs-15" placeholder={t('Offline License String')}  onChange={(e) => handlerOnFileChange(e.target.files)} ref={fileInputRef} />  
                                <button className="btn btn-primary rounded-pill w-100 mt-1" disabled={!licenseFile} onClick={() => generateOfflineActivationCode()}>{ createLicense ? t('키 파일 다운로드') : t('오프라인 활성화 코드 생성')}</button>
                            </div>
                        </div>}
                        {isDone && <div className="col-lg-8 col-xl-7 col-xxl-6 mx-auto text-center pt-8">
                            <h1 className="mb-3">{ createLicense ? t('키 파일 생성 완료') : t('오프라인 활성화 코드 생성 완료')}</h1>
                            <div className="mb-7 px-md-12 px-lg-5 px-xl-7">
                                <label className="lead">{ createLicense ? t('키 파일 생성이 완료되었습니다.') : t('오프라인 활성화 코드 생성이 완료되었습니다.')}</label>
                            </div>
                            <div className="mb-7 px-md-12 px-lg-5 px-xl-7 text-start mx-4">
                                <div>
                                    <label className="lead h5">
                                        {t('사용방법 안내')}
                                    </label>
                                </div>
                                <div className="mb-1 px-md-12 px-lg-5 px-xl-7">
                                    <label className="lead">{t('1. 다운로드 받은 Key 파일을 해당 PC에 복사하세요.')}</label>
                                </div>
                                <div className="mb-1 px-md-12 px-lg-5 px-xl-7">
                                    <label className="lead">{ createLicense ? t('2. 나머지 절차를 진행하세요.') : t('2. Start License Renewal 을 진행하세요.')}</label>
                                </div>
                            </div>
                            <div className="mb-7 px-md-12 px-lg-5 px-xl-7">
                                <a className="btn btn-primary rounded-pill mt-1" onClick={() => history.push('/')}>{t('홈으로')}</a>
                            </div>
                        </div>}
                    </div>}
                </div>
            </section>
        </>
        // </div>
    )
}

const mapState = (state) => {
    const user = state.AuthReducer.user;
    return { user };
}

const mapDispatch = dispatch => ({
});

export default connect(mapState, mapDispatch)(ReluRenewal);