import axios from 'axios';
import { JWT } from '../config';
import { getEnv } from './function';
import { EncryptData } from './rayEncrypt';

const CALL_DB_VERSION = "v2";

export const DoDB = async (type, data, region = "ap-northeast-2") => {
    const payload = {
        data: {
            type,
            region,
            params: data
        }
    };
    if (CALL_DB_VERSION === "v2") {
        return callDynamoV2(payload);
    }
    return callDynamoV1(payload);
};

async function callDynamoV1(payload) {
    try {
        const apiUrl = getEnv() === "development" ? "https://api-teams-info.raydevelop.com/common/dynamo" :
            "https://api-teams-info.rayteams.com/common/dynamo";
        const ret = await axios.post(apiUrl, payload, JWT());
        return ret?.data;
    } catch (error) {
        console.error("ERROR[callDynamoV1]", error);
        return [];
    }
};

async function callDynamoV2(originPayload) {
    try {
        const apiUrl = getEnv() === "development" ? "https://api-teams-info.raydevelop.com/common/calldynamo" :
            "https://api-teams-info.rayteams.com/common/calldynamo";
        const payload = await EncryptData(originPayload.data);
        if (window?.location?.origin.startsWith("http://localhost:")) {
            payload.debug = originPayload;
        }
        const ret = await axios.post(apiUrl, payload);
        return ret?.data;
    } catch (error) {
        console.error("ERROR[callDynamoV2]", error);
        return [];
    }
}
