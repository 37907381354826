import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BoardAction } from '../redux/action/BoardAction';

const ContentView = ({ cid }) => {

    const { t, i18n } = useTranslation(['translation']);
    const [ info, setInfo ] = useState({});

    useEffect(() => {
        getInfo()
    }, [i18n.language, cid]);

    const getInfo = async () => {
        const tmp = await BoardAction.GetSupportBoardContents(cid, i18n.language);
        setInfo(tmp);
    }

    const extractInnerHTML = (htmlString, splitStr = " ") => {
        if (!htmlString || typeof htmlString !== "string")
            return htmlString;
        const regex = /(<([^>]+)>)/ig;
        return htmlString.replace(regex, splitStr);
    };

    return (
        <div className="card-body" dangerouslySetInnerHTML={{ __html: info?.content }}></div>
    )
}

export default ContentView;
